import dayjs from 'dayjs';

export const getFormatDateWithTime = (date: Date | string) => {
  return dayjs(date).format('YYYY-MM-DD hh:mm A');
};

export const getFormatDate = (date: Date | undefined | string) => {
  return dayjs(date).format('YYYY/MM/DD');
};

export const getFormatDateWithDash = (date: Date | undefined | string) => {
  return dayjs(date).format('YYYY-MM-DD');
};

export const getFormattedDateShortDashed = (date: Date | undefined | string) => {
  return dayjs(date).format('YY-MM-DD');
};

export const getISOStringFromDate = (date: Date) => {
  return date.toISOString();
};
