import { handleNumberFieldInputValidate } from '../../utils/inputHandler';

export const ADDRESS_FORM = {
  title: 'Organization address',
  fullAddress: {
    label: 'Full address',
    name: 'fullAddress',
    inputProps: { maxLength: 128 },
    rules: { required: 'Please enter full address' },
  },
  detailAddress: {
    label: 'Detail address',
    name: 'detailAddress',
    inputProps: { maxLength: 128 },

    rules: { required: 'Please enter detail address' },
  },
  zipCode: {
    label: 'ZIP / Postal code',
    name: 'zipCode',
    inputProps: { maxLength: 7, onInput: handleNumberFieldInputValidate },
    rules: { required: 'Please enter ZIP / Postal code' },
  },
};

export const ORGANIZATION_NAME_FORM = {
  title: 'Organization name',
  name: {
    label: 'Organization name',
    name: 'name',
    inputProps: { maxLength: 128 },
    rules: { required: 'Please enter organization name' },
  },
};

export const PHONE_NUMBER_FORM = {
  title: 'Phone number',
  number: {
    label: 'Phone number',
    name: 'businessPhone',
    inputProps: { maxLength: 28, onInput: handleNumberFieldInputValidate },
    rules: { required: 'Please enter phone number' },
    helperText: 'Enter the full phone number with area code, no hyphens, no country code',
  },
};

export const ORGANIZATION_CODE_FORM = {
  DENTAL_CLINIC: {
    title: 'Medical institution code',
    name: 'corporateNumber',
    inputProps: { maxLength: 10, onInput: handleNumberFieldInputValidate },
    rules: { required: 'Please enter medical institution code' },
  },
  DENTAL_LAB: {
    title: 'Dental lab code',
    name: 'corporateNumber',
    inputProps: { maxLength: 28 },
    rules: { required: 'Please enter dental lab code' },
  },
};

export const ORGANIZATION_DIALOGUE_STATE = {
  completeProfile: {
    title: 'Complete profile',
    list: [
      { value: 'Please verify affiliated organization to use Dentbird Connect.' },
      { value: 'You can search organization by phone number or register a new one.' },
    ],
  },
  registerOrganization: {
    title: 'Register organization',
    list: [
      { value: 'Please fill in the fields below to use Dentbird Connect.' },
      {
        value:
          'Registering with disposable or fake information can compromise your security and hinder your ability to receive support.',
      },
    ],
  },
};
