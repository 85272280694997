import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useUserQuery } from '../api/userQuery';

const i18nLang = ['en', 'ja'];

export const I18nHoc = ({ children }: { children: React.ReactNode }) => {
  const { i18n } = useTranslation();
  const { data, isLoading } = useUserQuery();
  const userData = data?.data;

  // useEffect(() => {
  //   if (userData) {
  //     const lang = userData?.localeCode?.toLowerCase();
  //     i18n.changeLanguage(lang && i18nLang.includes(lang) ? lang : 'ja');
  //   }
  // }, [userData]);

  useEffect(() => {
    if (userData) {
      const lang = userData?.localeCode?.toLowerCase();
      if (lang) {
        i18n.changeLanguage(i18nLang.includes(lang) ? lang : 'ja');
      } else {
        i18n.changeLanguage('ja');
      }
    }
  }, [userData, i18n]);

  if (isLoading) return null;
  return <>{children}</>;
};
