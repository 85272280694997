import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Button, Divider, theme } from '@imago-cloud/design-system';
import { Box, Stack } from '@mui/material';
import { groupType, hook, OrderItemRequest, shadeType } from 'client/types.gen';
import NewProductItemList from 'pages/NewOrder/components/Prescription/NewProductItemList';
import NoSelectedText from 'pages/NewOrder/components/Prescription/NoSelectedText';
import Note from 'pages/NewOrder/components/Prescription/Note';
import ProductOptionDialog from 'pages/NewOrder/components/Prescription/ProductOptionDialog/ProductOptionDialog';
import ToothFormSelector from 'pages/NewOrder/components/Prescription/ToothFormSelector';
import { priceMeasuredOrderDataState } from 'pages/NewOrder/store/atoms';
import { useRecoilState } from 'recoil';
import { useDialogue } from 'shared/hooks/useDialogue';
import { v4 as uuidv4 } from 'uuid';

import { useOrderCreateMutation, useRemakeCreateMutation } from '../../../../api/orderQuery';
import { useUserQuery } from '../../../../api/userQuery';
import { CustomSelectorToFDI } from '../../../../shared/utils/convertToPalmer';
import { useGlobalDialog, useTopAlert } from '../../../../store';
import { UNABLE_ORDER_PRODUCT } from '../../constants/dialog';
import useNewOrderDataContext from '../../context/useNewOrderDataContext';
import BackStepTitle from '../BackStepTitle';
import SplitHalfLayout from '../SplitHalfLayout';

export type NewProductDisplayItemType = OrderItemRequest & { requireScanBodyAndImplantInfo: boolean };
export const SingleProductDefaultValue = {
  productId: '',
  designManufactureCombination: 'DESIGN_AND_MANUFACTURE',
  // price: 0,
  hook: 'NONE' as hook,
  shadeType: 'NONE' as shadeType,
  requireScanBodyAndImplantInfo: false,
  scanBodyDetail: '',
  implantDetail: '',
  toothNumber: 0,
  groupId: undefined,
  groupType: undefined,
  premiumFinishing: false,
  // designConfirmProcessIncluded: false,
  // remakeItemInfo: undefined,
  prosthesis: '',
  method: '',
  material: '',
  shape: '',
} as NewProductDisplayItemType;

type OrderFormPropType = {
  onPrevious: () => void;
  onNext: () => void;
};

// const customOrder = [18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28];
// const orderMap = new Map(customOrder.map((value, index) => [value, index]));
// const customSort = (a, b) => orderMap.get(a.toothNumber) - orderMap.get(b.toothNumber);

export default function Prescription({ onPrevious, onNext }: OrderFormPropType) {
  const { t } = useTranslation();
  const { setTopAlert } = useTopAlert();
  const { addDialog } = useGlobalDialog();

  const { open, handleOpenDialogue, handleCloseDialogue } = useDialogue();
  const { orderData, setOrderData } = useNewOrderDataContext();
  const { orderItems, note } = orderData;

  const { data: userData } = useUserQuery();
  const [priceMeasuredOrderData, setPriceMeasuredOrderData] = useRecoilState(priceMeasuredOrderDataState);
  const { mutateAsync: orderCreateMutate, isPending: orderCreatePending } = useOrderCreateMutation();
  const { mutateAsync: remakeCreateMutate, isPending: remakeCreatePending } = useRemakeCreateMutation();

  const [productToAdd, setProductToAdd] = useState<OrderItemRequest>(SingleProductDefaultValue);

  const { pathname } = useLocation();
  const orderId = pathname.split('/').pop() ?? '';

  const selectedBridgeNumberPairList = orderItems
    .sort((a, b) => a.toothNumber - b.toothNumber)
    .reduce<Array<OrderItemRequest>[]>((acc, item) => {
      const lastGroup = acc[acc.length - 1];
      if (
        lastGroup &&
        lastGroup[lastGroup.length - 1].toothNumber === item.toothNumber - 1 &&
        lastGroup[0].material === item.material &&
        item.groupType === 'BRIDGE' &&
        lastGroup[0].groupId === item.groupId
      ) {
        // console.log('Bridge push Item: ', item);
        // console.log('push Item LastGroup: ', lastGroup);
        lastGroup.push(item);
      } else {
        // console.log('acc Push item: ', item);
        acc.push([item]);
      }
      // console.log('PRES: ', acc);
      return acc;
    }, [])
    .filter((group) => {
      // console.log(group.map((pair) => console.log(pair)));
      // console.log(group.length);
      return group.length > 1;
    }) // [[{... toothNumber: 11}, {..., toothNumber: 12} ]]
    .flatMap((group) => {
      console.log('Prescription: ', group);
      const pairs: Array<string> = [];
      for (let i = 0; i < group.length; i++) {
        // console.log('PRES group: ', group[i]);
        if (group[i] === group[group.length - 1])
          continue; // 마지막 요소 진행 x
        else if (group[i].groupId && group[i + 1].groupType) {
          pairs.push(`${group[i].toothNumber}-${group[i + 1].toothNumber}`);
        }
      }
      // console.log('SELECTED PAIRS 0', pairs);
      return pairs;
    });
  console.log(selectedBridgeNumberPairList);

  // for Tooth Selector display
  const selectedToothList = orderItems
    .map((item) => ({
      prosthesis: item.prosthesis,
      material: item.material,
      toothNumber: item.toothNumber,
      groupId: item.groupId,
    }))
    .concat({
      prosthesis: productToAdd.prosthesis,
      material: productToAdd.material,
      toothNumber: productToAdd.toothNumber,
      groupId: productToAdd.groupId,
    });

  const handleToothClick = (clickedToothNumber: number) => {
    const alreadyIncluded = selectedToothList.some((tooth) => tooth.toothNumber === clickedToothNumber);
    if (alreadyIncluded) return;

    setProductToAdd((prev) => ({ ...prev, toothNumber: clickedToothNumber }));
    handleOpenDialogue();
  };

  const handleBridgeClick = (clickedBridgeNumberPair: string) => {
    const isAlreadySelected = selectedBridgeNumberPairList.includes(clickedBridgeNumberPair);
    const toothPair = clickedBridgeNumberPair.split('-');

    console.log(isAlreadySelected);
    console.log(toothPair);

    // 해제할 때:
    if (isAlreadySelected) {
      // 처음 또는 마지막 브릿지 / 또는 2개짜리 브릿지.
      const clickedToothList = orderItems
        .filter((item) => toothPair.includes(item.toothNumber.toString()))
        .sort((a, b) => a.toothNumber - b.toothNumber);
      const commonGroupId =
        clickedToothList[0].groupId === clickedToothList[1].groupId ? clickedToothList[1].groupId : null;
      const orderItemsWithCommonGroupId = orderItems
        .filter((item) => item.groupId === commonGroupId)
        .sort((a, b) => a.toothNumber - b.toothNumber);

      // 처음 또는 마지막 브릿지 pair 인지 또는 2개짜리 브릿지인지 판단. // PrecedingTooth => 치식번호가 낮은 치아.
      const isTheFirstItemPrecedingTooth = // 브릿지 맨 처음 치아인가
        orderItemsWithCommonGroupId.findIndex((item) => item.toothNumber.toString() === toothPair[0]) === 0;
      const isTheLastItemTrailingTooth = // 브릿지 맨 마지막 치아인가
        orderItemsWithCommonGroupId.findIndex((item) => item.toothNumber.toString() === toothPair[1]) ===
        orderItemsWithCommonGroupId.length - 1;

      console.log(orderItemsWithCommonGroupId);
      // 가장 일반적인 2개 치식짜리 브릿지 해제.
      if (orderItemsWithCommonGroupId.length === 2) {
        const updatedItemList = orderItems.map((item) => {
          if (item.groupId === commonGroupId) {
            return { ...item, groupType: null, groupId: undefined };
          } else {
            return item;
          }
        });
        return setOrderData((prev) => ({ ...prev, orderItems: updatedItemList }));
      } else if (isTheFirstItemPrecedingTooth || isTheLastItemTrailingTooth) {
        // 그 외 치식 3개 이상 브릿지
        const updatedItemList = orderItems.map((item) => {
          if (
            isTheFirstItemPrecedingTooth &&
            item.groupId === commonGroupId &&
            item.toothNumber.toString() === toothPair[0]
          ) {
            console.log('isTheFirstItemPrecedingTooth', isTheFirstItemPrecedingTooth);
            return { ...item, groupType: null, groupId: undefined };
          } else if (
            isTheLastItemTrailingTooth &&
            item.groupId === commonGroupId &&
            item.toothNumber.toString() === toothPair[1]
          ) {
            console.log('isTheLastItemTrailingTooth', isTheLastItemTrailingTooth);
            return { ...item, groupType: null, groupId: undefined };
          } else {
            return item;
          }
        });
        return setOrderData((prev) => ({ ...prev, orderItems: updatedItemList }));
      } else {
        // 4개 이상의 치아로 구성된 하나인 브릿지에서 가운데를 끊어서 각각 2개의 브릿지로 나누려고 할 때.
        const clickedToothPair = orderItems.filter((item) => toothPair.includes(item.toothNumber.toString()));

        const trailingTooth = clickedToothPair[1];
        const trailingToothNumberList = orderItems
          .filter((item) => {
            return trailingTooth.groupId === item.groupId && trailingTooth.toothNumber <= item.toothNumber;
          })
          .map((item) => item.toothNumber);

        const newRandomId = uuidv4();
        const updatedItemList = orderItems.map((item) => {
          if (trailingToothNumberList.includes(item.toothNumber)) {
            return { ...item, groupId: newRandomId };
          } else {
            return item;
          }
        });
        setOrderData((prev) => ({ ...prev, orderItems: updatedItemList }));
      }
    } else {
      // 선택할 때:
      // 서로 다른 두 개의 브릿지 가운데 선택해서 하나의 브릿지로 만드는 때
      const clickedToothPair = orderItems.filter((item) => toothPair.includes(item.toothNumber.toString())); // sort 굳이
      const isAllNeighbouringToothBridged = clickedToothPair.every((item) => item.groupId !== undefined);

      if (isAllNeighbouringToothBridged) {
        const overwritingId = clickedToothPair[0].groupId; // 덮어쓸때 사용할 id
        const overwrittenId = clickedToothPair[1].groupId; // 덮어씌워지는 id
        const updatedItemList = orderItems.map((item) => {
          if (item.groupId === overwrittenId) {
            return { ...item, groupId: overwritingId };
          } else {
            return item;
          }
        });
        return setOrderData((prev) => ({ ...prev, orderItems: updatedItemList }));
      } else {
        // 아무것도 없을 때 일반선택 / 순차적 선택할 때
        const randomId = uuidv4();
        const adjacentGroupId = clickedToothPair.find((obj) => obj.groupId !== undefined)?.groupId;

        const updatedItemList = orderItems.map((item) => {
          // if (item.toothNumber === +toothPair[0] || item.toothNumber === +toothPair[1]) {
          if (toothPair.includes(item.toothNumber.toString())) {
            return {
              ...item,
              groupType: 'BRIDGE' as groupType,
              groupId: adjacentGroupId ? adjacentGroupId : randomId,
            };
          } else {
            return item;
          }
        });
        return setOrderData((prev) => ({ ...prev, orderItems: updatedItemList }));
      }
    }
  };

  const { binaryFiles, printingFilesDisplay, remakeOrderInfo, ...createOrderData } = orderData;
  const convertedToFDI = { ...createOrderData }.orderItems.map((item) => {
    const newItem = { ...item };
    console.log(newItem.toothNumber);
    newItem.toothNumber = CustomSelectorToFDI[newItem.toothNumber];
    console.log(newItem);
    return newItem;
  });
  console.log(createOrderData);
  console.log(convertedToFDI);
  // const updatedOrderData = { ...createOrderData, ...convertedToFDI };
  // console.log(orderData);
  // console.log(orderItems);
  // console.log(createOrderData);
  // console.log(updatedOrderData);

  const handleApplyClick = ({
    method,
    material,
    shape,
    productId,
    requireScanBodyAndImplantInfo,
  }: {
    method: string;
    material: string;
    shape: string;
    productId: string;
    requireScanBodyAndImplantInfo: boolean;
  }) => {
    console.log(method, material, productId);
    if (productToAdd.toothNumber === 0 || !method || !material || !productId) {
      throw Error('고장남! api 확인 필요.');
    }
    const newProduct = {
      ...productToAdd,
      designManufactureCombination: orderData.designManufactureCombination,
      productId,
      method,
      material,
      shape: shape ?? undefined,
      requireScanBodyAndImplantInfo,
    };

    setOrderData((prev) => ({
      ...prev,
      orderItems: [...prev.orderItems, newProduct],
    }));
    setProductToAdd(SingleProductDefaultValue);
  };

  const handleNextButtonClick = async () => {
    if (hasUnselectedShade) {
      setTopAlert({ open: true, description: t('alert_select_tooth_shade.title'), severity: 'warning' });
      return;
    }

    if (orderData && userData) {
      try {
        const { binaryFiles, printingFilesDisplay, remakeOrderInfo, ...createOrderData } = orderData;
        let res;
        const convertedToFDI = createOrderData.orderItems.map((item) => {
          const newItem = { ...item };
          console.log(newItem.toothNumber);
          newItem.toothNumber = CustomSelectorToFDI[newItem.toothNumber];
          console.log(newItem);
          return newItem;
        });
        const updatedOrderData = { ...createOrderData, orderItems: convertedToFDI };

        if (pathname.includes('remake')) {
          res = await remakeCreateMutate({
            ...updatedOrderData,
            remadeBy: orderId,
            remakeOrderInfo: { originalId: remakeOrderInfo?.originalId ?? orderId },
          });
        } else {
          res = await orderCreateMutate(updatedOrderData);
        }
        setPriceMeasuredOrderData(res.data);
        onNext();
      } catch (e: any) {
        const errorData = await e.response.json();
        if (errorData.errorCode === 'CONNECT:NOT_FOUND') addDialog(UNABLE_ORDER_PRODUCT);
        console.error('Error POST Orders: ', errorData);
      }
    }
  };

  const isNextButtonDisabled = orderItems.length === 0;
  const hasUnselectedShade = orderItems?.some((item) => item.shadeType === 'NONE');

  return (
    <>
      <SplitHalfLayout>
        <SplitHalfLayout.Left
          sxProps={{
            position: 'relative',
          }}
        >
          {/* Left */}
          <BackStepTitle
            stepName={t('prescription_field.title_prescription')}
            order={'2/3'}
            onPrevious={onPrevious}
            isDirty={true}
          />
          <Stack
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{
              position: 'relative',
              width: '100%',
              minWidth: '430px',
              height: '100%',
            }}
          >
            <ToothFormSelector
              onToothClick={handleToothClick}
              selectedTooth={selectedToothList}
              onBridgeClick={handleBridgeClick}
              selectedBridgeNumberPairList={selectedBridgeNumberPairList}
            />
          </Stack>
          <Note />
        </SplitHalfLayout.Left>

        <SplitHalfLayout.Right sxProps={{ width: '100%', backgroundColor: theme.palette.grey['100'] }}>
          {orderItems.length === 0 ? <NoSelectedText /> : <NewProductItemList onOpenDialogue={handleOpenDialogue} />}
          <Box sx={{ padding: '8px 16px 16px' }}>
            <Divider />
            <Button
              onClick={handleNextButtonClick}
              disabled={isNextButtonDisabled}
              size="60"
              loading={orderCreatePending || remakeCreatePending}
              sx={{ width: '100%' }}
            >
              {t('teeth_form.btn_next')}
            </Button>
          </Box>
        </SplitHalfLayout.Right>
      </SplitHalfLayout>

      <ProductOptionDialog
        open={open}
        onClose={() => {
          handleCloseDialogue();
          setProductToAdd(SingleProductDefaultValue);
        }}
        productToAdd={productToAdd}
        setProductToAdd={setProductToAdd}
        onApplyClick={handleApplyClick}
      />
    </>
  );
}
