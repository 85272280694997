import { UseQueryResult } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadEmscriptenModule } from '@imago/iwtk.js/dist';
import { Badge, Button, Cicon, IconButton, Loading, Ricon, theme, Typography } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { ChatIcon } from 'assets/index';
import { FileResponse } from 'client/types.gen';
import { FILE_EXTENSIONS_3D } from 'shared/constants/file';
import { useVTKContext } from 'shared/hooks/useVTKContext';
import { getOrderFileToRenderingData } from 'shared/utils/Viewer/convertOrderFileToRenderingData';

import { useUnreadChannelMessageCount } from '../../../../shared/hooks/Sendbird/useCreateChannel';
import { getCurrentOrderStatus } from '../../../../shared/utils/getOrderStatusConfig';
import { ChatInfo } from '../../OrderDetail';
import ViewerChatWindow from '../ViewerChatWindow';
import { ModelList } from './ModelList';

const RenderingArea = ({
  isEmptyCase,
  isLoading,
  chatInfo,
}: {
  isEmptyCase: boolean;
  isLoading: boolean;
  chatInfo: ChatInfo;
}) => {
  const { t } = useTranslation();
  const viewerRef = useRef<HTMLElement>();
  const { setRenderWindowContainer, handleResetCamera } = useVTKContext();

  // Chat

  const { unreadMessageCount, getUnreadMessageCount } = useUnreadChannelMessageCount(
    chatInfo.isChatAvailable ? (chatInfo?.chattingChannelUrl as string) : '',
  );
  const [isChatOpen, setIsChatOpen] = useState(false);

  const currentOrder = getCurrentOrderStatus();

  const handleChatClick = async () => {
    setIsChatOpen((prev) => !prev);
    await getUnreadMessageCount();
  };

  const handleZoomToFitClick = () => {
    handleResetCamera('main');
  };

  useEffect(() => {
    if (viewerRef.current) {
      setRenderWindowContainer(viewerRef.current, 'main');
    }
  }, [setRenderWindowContainer, viewerRef]);

  return (
    <Stack
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.canvas,
      }}
    >
      {isLoading && (
        <Stack
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.background.canvas,
          }}
        >
          <Stack
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: '10',
            }}
          >
            <Loading size="44" type="basic" />
          </Stack>
        </Stack>
      )}

      {!isLoading && isEmptyCase && (
        <Stack
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            gap: '8px',
            alignItems: 'center',
          }}
        >
          <Typography variant="H24" color={theme.palette.text.secondary}>
            {t('patient_page_/_no_3d_files.title')}
          </Typography>
          <Typography variant="Subtitle_Semibold16" color={theme.palette.text.secondary}>
            {t('patient_page_/_no_3d_files.body')}
          </Typography>
        </Stack>
      )}
      <Stack ref={viewerRef as any} sx={{ width: '100%', height: '100%' }} />
      {!isLoading && (
        <>
          <Stack
            sx={{
              // width: '100%',
              position: 'absolute',
              top: '20px',
              left: '20px',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <IconButton
              size="36"
              color="grey800"
              shadow={true}
              icon_size="small"
              hover_color="grey"
              variant="outlined"
              onClick={() => {
                window.history.back();
              }}
            >
              <Ricon icon="ri-arrow-left-s-line" />
            </IconButton>

            <Stack sx={{ flexDirection: 'row', ml: '16px', gap: '8px' }}>
              <Typography variant={'Subtitle_Semibold16'} color={theme.palette.text.thirdary}>
                {currentOrder === 'orderer' ? t('breadcrumbs.my_orders') : t('breadcrumbs.received_orders')}
              </Typography>
              <Typography variant={'Subtitle_Semibold16'} color={theme.palette.text.thirdary}>
                /
              </Typography>
              <Typography variant={'Subtitle_Semibold16'} color={theme.palette.text.thirdary}>
                {t('breadcrumbs.patient_page')}
              </Typography>
            </Stack>
          </Stack>
          {chatInfo.isChatAvailable && (chatInfo.chattingChannelUrl as string) && (
            <>
              <Badge
                badgeContent={unreadMessageCount}
                color="primary"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                sx={{
                  position: 'absolute',
                  top: '40px',
                  right: '20px',
                  zIndex: 0,
                }}
              >
                <Button onClick={handleChatClick} size="60" color="grey" variant="outlined" startIcon={<ChatIcon />}>
                  {t('chat.messages')}
                </Button>
              </Badge>
              <ViewerChatWindow
                chatInfo={chatInfo}
                onClose={handleChatClick}
                isOpen={!!chatInfo.chattingChannelUrl && isChatOpen}
              />
            </>
          )}
        </>
      )}
      {!isEmptyCase && !isLoading && (
        <>
          <ModelList />
          <Button
            size="36"
            color="grey"
            shadow={true}
            hover_color="black"
            variant="outlined"
            startIcon={<Cicon icon="ZoomToFit" />}
            sx={{
              position: 'absolute',
              bottom: '20px',
              left: '20px',
              ':hover': {
                backgroundColor: theme.palette.action.hover,
              },
            }}
            onClick={handleZoomToFitClick}
          >
            {t('btn_zoom_to_fit.body')}
          </Button>
        </>
      )}
    </Stack>
  );
};

export interface IParsedOrderFile extends FileResponse {
  textureFiles: FileResponse[];
}

export const Viewer = ({
  files,
  chatInfo,
}: {
  files: Record<'patientFile' | 'designedFile', Array<FileResponse>>;
  chatInfo: ChatInfo;
}) => {
  const { patientFile, designedFile } = files;
  const { renderingToRenderer } = useVTKContext();

  const isDesignedFileEmpty = Array.isArray(designedFile) && designedFile.length === 0;
  const fileToProcess = isDesignedFileEmpty ? patientFile : designedFile;
  const orderFile = fileToProcess?.filter(({ fileName }) => FILE_EXTENSIONS_3D.some((type) => fileName.endsWith(type)));
  const isMountedRef = useRef(false);
  const prevFileToProcessRef = useRef(fileToProcess);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const loadData = async () => {
      await loadEmscriptenModule();
      const newRenderingDataList = await getOrderFileToRenderingData(orderFile);
      renderingToRenderer(newRenderingDataList, 'main');
      setIsLoading(false);
    };

    if (!isMountedRef.current) {
      loadData();
      isMountedRef.current = true;
      prevFileToProcessRef.current = fileToProcess;
    }
    //design file 업로드 되었을때 즉시 렌더링
    else if (prevFileToProcessRef.current !== fileToProcess && designedFile && fileToProcess.length > 0) {
      setIsLoading(true);
      loadData();
      prevFileToProcessRef.current = fileToProcess;
    }
  }, [fileToProcess, renderingToRenderer]);

  return <RenderingArea isEmptyCase={orderFile.length === 0} isLoading={isLoading} chatInfo={chatInfo} />;
};
