import { UseQueryResult } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { theme } from '@imago-cloud/design-system';
import { Stack } from '@mui/material';
import { OrderDetailResponse, status } from 'client/types.gen';
import SplitHalfLayout from 'pages/NewOrder/components/SplitHalfLayout';
import { ActionArea } from 'pages/OrderDetail/components/ActionArea';
import { InformationArea } from 'pages/OrderDetail/components/InformationArea';
import { PatientArea } from 'pages/OrderDetail/components/Rx/PatientArea';

import { useOrderDetailQuery } from '../../api/orderQuery';
import { useUserQuery } from '../../api/userQuery';
import CustomSendbirdProvider from '../../shared/hooks/Sendbird/CustomSendbirdProvider';
import { Viewer } from './components/Viewer/Viewer';

const userChatAvailableOrderStatus = [
  'ORDERED',
  'DESIGNING',
  'WAITING_DESIGN_CONFIRMATION',
  'MANUFACTURING',
  'SHIPPED',
  'COMPLETED',
];

const partnerLabChatAvailableOrderStatus = [
  'DESIGNING',
  'WAITING_DESIGN_CONFIRMATION',
  'MANUFACTURING',
  'SHIPPED',
  'COMPLETED',
];
const isChatAvailable = (userEmail: string, ordererEmail: string, orderStatus: status) => {
  const amIOrderer = userEmail === ordererEmail;
  // 파트너랩도 오더 가능: 진입할 수 있다면 assign 된 것.
  const amIPartnerLab = userEmail !== ordererEmail;
  if (amIOrderer && userChatAvailableOrderStatus.includes(orderStatus)) {
    return true;
  } else if (amIPartnerLab && partnerLabChatAvailableOrderStatus.includes(orderStatus)) {
    return true;
  }
  return false;
};

export type ChatInfo = {
  isChatAvailable: boolean;
  orderId: string;
  chattingChannelUrl: string | undefined;
  refetch: () => Promise<UseQueryResult>;
};

export default function OrderDetail() {
  const {
    data: {
      data: { email },
    },
  } = useUserQuery();
  // console.log(user.data);
  const { pathname } = useLocation();
  const orderId = pathname.split('/').pop() ?? '';

  const { data, refetch } = useOrderDetailQuery(orderId);
  const orderDetail = data?.data as OrderDetailResponse;
  const chatInfo = {
    isChatAvailable: isChatAvailable(email, orderDetail.orderer.email, orderDetail.status),
    orderId: orderDetail.id,
    chattingChannelUrl: orderDetail.chattingChannelUrl ?? orderDetail.id,
    refetch,
  };

  return (
    <CustomSendbirdProvider>
      <SplitHalfLayout>
        {/*Case Viewer*/}
        <SplitHalfLayout.Left>
          <Viewer
            key={orderId}
            chatInfo={chatInfo}
            files={{
              patientFile: orderDetail.patientFilesResponse,
              designedFile: orderDetail.designedFilesResponse,
            }}
          />
        </SplitHalfLayout.Left>
        {/*Order details*/}
        <SplitHalfLayout.Right
          sxProps={{
            justifyContent: 'flex-start',
            backgroundColor: theme.palette.background.white,
          }}
        >
          {/*Common -> 기본정보*/}
          <PatientArea orderDetail={orderDetail} />
          <Stack sx={{ minHeight: '16px', backgroundColor: theme.palette.grey['100'] }} />
          <ActionArea orderDetail={orderDetail} />
          <Stack sx={{ minHeight: '16px', backgroundColor: theme.palette.grey['100'] }} />
          <InformationArea orderDetail={orderDetail} />
        </SplitHalfLayout.Right>
      </SplitHalfLayout>
    </CustomSendbirdProvider>
  );
}
